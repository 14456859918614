<template>
    <!-- NOTE: IMPORTANT: -->
    <!-- Any changes made to this file's html/vue needs to be made in 2 different places -->
    <!-- because there is an "v-if" and "v-else" used here that both use the same content but it is a workaround -->
    <!-- to handle both the legacy code implementation as well as the proper design system implementation -->
    <!-- please do not miss doing this in both places, if you don't, then 1 place will not reflect the changes -->
    <!-- NOTE: IMPORTANT: -->
    <div>
        <hb-modal v-model="modalDialog" size="large" @click="closeDialogue" show-help-link>
            <template v-slot:title>
                <hb-btn icon tooltip="Back" @click="closeEdit" mdi-code="mdi-chevron-left" active-state-off
                    :color="state === 'view' ? '#101318' : ''" :hover-background-color="modal ? '#CAEAEA' : ''">
                </hb-btn>
                <span class="ml-1">
                    Invoice # {{ invoice.invoice_number }}
                </span>
            </template>
            <template v-slot:content>
                <div>
                    <div class="px-6 py-4" style="background: #F9FAFB;">
                        <v-row class="mx-0">
                            <v-col cols="5" class="pa-0 pr-4">
                                <v-card class="hb-elevation-medium hb-border-default">
                                    <v-card-title class="pa-4 pb-0">
                                        <span class="subtitle-1 font-weight-medium text-capitalize">National Account name </span>
                                    </v-card-title>
                                    <v-card-text class="px-4">
                                        <span class="subtitle-1">Address</span><br>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="7" class="pa-0">
                                <v-card class="hb-elevation-medium hb-border-default">
                                    <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell"
                                        style="flex-wrap: unset;">
                                        <v-col cols="4" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">Invoice ID:</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="4" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">Invoice Date:</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="4" class="d-flex align-center">
                                            <span class="ml-3 subtitle-1 font-weight-medium">Due Date</span>
                                        </v-col>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="d-flex pa-0 ma-0 hb-default-font-size">
                                        <v-col cols="4" class="d-flex align-center justify-center">
                                            <span class="subtitle-1 font-weight-regular hb-text-night">{{
            invoice.invoice_number }}</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="4" class="d-flex align-center pr-0">
                                            <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{
            invoice.date
                                                | formatDateServices }}</span>
                                        </v-col>
                                        <v-divider vertical></v-divider>
                                        <v-col cols="4" class="d-flex align-center pr-0">
                                            <span class="ml-3 subtitle-1 font-weight-regular hb-text-night">{{
                                                invoice.due
                                                | formatDateServices }}</span>
                                        </v-col>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell"
                                style="flex-wrap: unset;">Summary</v-card-title> -->
                    <v-row class="mx-0 mt-4">
                        <v-col class="pa-0">
                            <v-card class="hb-border-default" elevation="0">
                                <v-card-title class="d-flex align-center ma-0 pa-0 inv-header-cell"
                                    style="flex-wrap: unset;">
                                    <v-col cols="2" class="d-flex align-center py-1">
                                        <span class="ml-3 subtitle-2 font-weight-medium">Department</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center py-1">
                                        <span class="subtitle-2 font-weight-medium">Total Centres</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center py-1">
                                        <span class="subtitle-2 font-weight-medium">Total Units</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center py-1">
                                        <span class="subtitle-2 font-weight-medium">Amount</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center py-1">
                                        <span class="subtitle-2 font-weight-medium">GST</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex align-center py-1">
                                        <span class="subtitle-2 font-weight-medium">Amt (inc GST)</span>
                                    </v-col>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pa-0 ma-0 hb-default-font-size">
                                    <v-row class="d-flex pa-0 mx-0" v-for="(line, index) in invoice.departments" :key="index">
                                        <v-col cols="2" class="d-flex align-center py-1">
                                            <span style="font-size:10px;"> {{line.name }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center py-1">
                                            <span style="font-size:10px;">  {{line.total_units}}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center py-1">
                                            <span style="font-size:10px;"> {{line.total_units}}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center py-1">
                                            <span style="font-size:10px;"> {{line.total_price | formatMoney }}   </span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center py-1">
                                            <span style="font-size:10px;">  {{line.total_tax | formatMoney }}   </span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center py-1">
                                            <span style="font-size:10px;">  {{line.total_sum | formatMoney }} </span>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row class="d-flex pa-0 mx-0">
                                        <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                                            <span class="subtitle-1">Sub Total (excl GST):</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                                            <span class="subtitle-1">{{ invoice.total_price | formatMoney }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="d-flex pa-0 mx-0">
                                        <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                                            <span class="subtitle-1">GST Amount</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                                            <span class="subtitle-1">{{ invoice.total_tax | formatMoney }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row class="d-flex pa-0 mx-0">
                                        <v-col cols="10" class="d-flex align-center justify-end px-0 py-1">
                                            <span class="subtitle-1">Invoice Total (inc GST)</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex align-center justify-end pl-0 py-1">
                                            <span class="subtitle-1">{{ invoice.total_sum | formatMoney }}</span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </template>

        </hb-modal>
    </div>
</template>

<script type="text/babel">
import Modal from '../assets/Modal.vue';
import PulseLoader from '../assets/DotLoader.vue';
import DropdownMenu from '../assets/DropdownMenu.vue';
import RecordPayment from '../leases/RecordPayment.vue';
import ViewInvoice from './ViewInvoice.vue';
import EditInvoice from './EditInvoice.vue';
import CreateInvoice from './CreateInvoice.vue';
import HbDatePicker from '../assets/HummingbirdDatepicker';
import SendInvoice from "../assets/SendInvoice.vue";
import Status from '../includes/Messages.vue';
import Loader from '../assets/CircleSpinner.vue';
import api from '../../assets/api.js';
import moment from 'moment';
import { EventBus } from '../../EventBus.js';
import { mapGetters } from 'vuex';
import { notificationMixin } from '../../mixins/notificationMixin.js';
import PaymentsNotificationModal from '../payments/PaymentsNotificationModal.vue';

export default {
    name: "DisplayNAInvoice",
    mixins: [notificationMixin],
    data() {
        return {
            state: 'view',
            invoice: {},
            invoiceBackup: {},
            payments: [],
            showVoidInvoice: false,
            showReissueInvoice: false,
            showEmptyInvoiceLineWarning: false,
            isLoadingFlag: false,
            paymentOptions: [],
            sendInvoiceModal: false,
            autoReconcile: "1",
            showAdjustmentConfirmation: false,
            adjustmentConfirmationIfNoPayment: false,
            adjustmentMode: false,
            showUnAppliedPaymentsUtilizationOptions: false,
            totalUnAppliedAmount: 0,
            dueDate: '',
            dateRange: {
                startDate: '',
                endDate: ''
            },
            paymentDetails: [],
            adjustPaymentOptions: [],
            is_auction_invoice: false,
            // Retained Revenue
            showRefundModal: false,
            showYieldButton: false,
            disableYieldButton: false,
            notificationModal: false,
            paymentTypes: [
                { text: "Retained Revenue", value: "retained_revenue" }
            ],
            refundToTypes: [
                { text: "Tenant", value: "tenant" },
                { text: "State", value: "state" }
            ],
            refund: {
                paymentFrom: 'retained_revenue',
                amount: 0,
                paymentTo: 'tenant'
            },
            is_inter_property_invoice: false
        }
    },
    components: {
        Modal,
        PulseLoader,
        DropdownMenu,
        Loader,
        RecordPayment,
        ViewInvoice,
        EditInvoice,
        CreateInvoice,
        Status,
        HbDatePicker,
        PaymentsNotificationModal,
        SendInvoice
    },
    created() {
        if (this.type == 'create') this.state = 'create';
        this.fetchData();
    },
    computed: {
        ...mapGetters({
            isAdmin: 'authenticationStore/isAdmin',
            hasPermission: 'authenticationStore/rolePermission'
        }),
        modalDialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        earliestPaymentDate() {
            let sortedDates = this.invoice.Payments.sort((a, b) => new Date(a.created) - new Date(b.created)).map(m => m.created);
            let earlistDate = sortedDates && sortedDates[0];
            // Need to verify why the utc conversion is done here?
            let offSet = moment(earlistDate).utcOffset();
            return moment.utc(earlistDate).utcOffset(offSet);
        },
        disableReissue() {
            let current_date = moment().format('YYYY-MM-DD');
            return (!this.invoice?.can_reissue || !(this.invoice?.Lease?.status == 1 && (this.invoice?.Lease?.end_date == null || moment(current_date).isAfter(this.invoice?.Lease?.end_date))));
        },
        reissueButton() {
            return this.state !== 'create' && (this.invoice.status == -1) && this.isAdmin
        },
        isRefundAmount() {
            return this.refund.amount ? false : true;
        },
        canVoidAdjust() {
            return (!this.invoice.can_void_adjust || this.is_inter_property_invoice || ['auction_payment', 'move_out'].includes(this.invoice.Lease?.auction_status))
        }
    },
    methods: {
        async voidConfirm() {
            const status = await this.$validator.validateAll("refund");
            if (!status) return

            let paymentDetl = [];

            for (var i = 0; i < this.paymentOptions.length; i++) {
                let p = this.paymentOptions[i].selectedOption
                if (p.type === 'refund') {
                    paymentDetl.push({
                        id: p.Payment.id,
                        type: p.type,
                        amount: p.amount,
                    })
                }
            }

            let data = {
                PaymentDetails: paymentDetl,
            };

            api.post(this, api.INVOICES + this.invoice_id + '/void', data).then(r => {
                this.showVoidInvoice = false;
                this.dialog = false;
                this.state = 'view';
                this.$emit('close');
                EventBus.$emit('invoice_saved');
                if (msg) {
                    this.successSet('TransactionHistory', `Invoice ${this.invoice.number} had been voided. The remaining balance has been placed in prepay/refund`);
                }
            });
        },
        async saveInvoiceAdjustment() {
            await this.saveAdjustedInvoice(true);

            if (!this.paymentDetails.length) {
                this.adjustmentConfirmationIfNoPayment = true
            }
            else {
                this.showUnAppliedPaymentsUtilizationOptions = true;
            }
        },
        closeShowEmptyInvoiceLineWarning() {
            this.showEmptyInvoiceLineWarning = false
            this.showUnAppliedPaymentsUtilizationOptions = false
            this.adjustmentConfirmationIfNoPayment = false;
        },
        getpaymentOptions() {
            this.paymentOptions = []
            this.invoice.Payments.forEach(p => {
                if (p.Payment.method && p.Payment.method === 'credit') {
                    this.paymentOptions.push({
                        payment_id: p.id,
                        options: [{
                            value: p.id,
                            text: `Apply ($${p.amount}) to a future charge`,
                            type: 'future',
                            disabled: !!this.invoice.Lease.moved_out,
                            ...p
                        }],
                        selectedOption: null
                    });
                    return;
                }

                let text = ''
                if (['card', 'ach'].indexOf(p.Payment.method) >= 0) {
                    text = p.PaymentMethod ? p.PaymentMethod.card_type + '****' + p.PaymentMethod.card_end : ''
                } else {
                    text = p.Payment.method
                }

                let options = [
                    {
                        value: p.id,
                        text: `Refund ($${p.amount}) to ${text}`,
                        type: 'refund',
                        ...p
                    }]

                if (this.invoice.lease_id) {
                    options.push({
                        value: 0,
                        text: `Apply ($${p.amount}) to a future charge`,
                        type: 'future',
                        disabled: !!this.invoice.Lease.moved_out,
                        ...p
                    });
                }

                this.paymentOptions.push({
                    payment_id: p.id,
                    options,
                    selectedOption: null
                })
            })
        },
        getAdjustPaymentOptions() {
            this.adjustPaymentOptions = []
            this.paymentDetails.forEach(p => {
                if (p.Payment.method && p.Payment.method === 'credit') {
                    this.adjustPaymentOptions.push({
                        options: [{
                            value: p.id,
                            text: `Apply ($${p.credit}) to a future charge`,
                            type: 'future',
                            disabled: !!this.invoice.Lease.moved_out,
                            ...p
                        }],
                        selectedOption: null
                    });
                    return;
                }

                let text = ''
                if (['card', 'ach'].indexOf(p.Payment.method) >= 0) {
                    text = p.PaymentMethod ? p.PaymentMethod.card_type + '****' + p.PaymentMethod.card_end : ''
                } else {
                    text = p.Payment.method
                }

                let options = [{
                    value: p.id,
                    text: `Refund ($${p.credit}) to ${text}`,
                    type: 'refund',
                    ...p
                }]

                options.push({
                    value: 0,
                    text: `Apply ($${p.credit}) to a future charge`,
                    value: p.credit,
                    type: 'future',
                    disabled: !!this.invoice.Lease.moved_out,
                    ...p
                });

                this.adjustPaymentOptions.push({
                    options,
                    selectedOption: null
                })
            })
        },
        showAdjustmentMode() {
            this.invoiceBackup = JSON.parse(JSON.stringify(this.invoice));

            this.adjustmentMode = true;
            this.showAdjustmentConfirmation = false;
            this.state = 'view';
        },
        adjustInvoice() {
            if (this.hasPermission("edit_invoices")) {
                this.showAdjustmentConfirmation = true;
            } else {
                this.notificationModal = true;
            }
        },
        closeEdit() {
            this.adjustmentMode = false
            this.state = 'view';
        },
        voidInvoice(invoice) {
            this.showVoidInvoice = true;
        },
        reissueInvoice(invoice) {
            this.showReissueInvoice = true;
        },
        fetchData() {
            this.isLoadingFlag = false
            if (!this.invoice_id) return;
            let i = api.get(this, api.NATIONAL_ACCOUNT + this.account_id + '/invoices/' + this.invoice_id).then(r => {

                this.invoice = r.invoice;
                console.log("agfuyasdgv", this.invoice)
                this.invoice.date = moment(this.invoice.date).format('MM/DD/YYYY');
                this.invoice.due = moment(this.invoice.due).format('MM/DD/YYYY');

                this.is_auction_invoice = this.isAuctionInvoiceFound();
                this.is_inter_property_invoice = this.isInterPropertyFound();
                this.getpaymentOptions();
                if (this.invoice.lease_id) {
                    api.get(this, api.LEASES + this.invoice.lease_id + "/billing_periods").then(r => {
                        if (r.billing_months && r.billing_months.current) {
                            let curr_period = r.billing_months.current;
                            this.dueDate = curr_period.start;
                            this.dateRange.startDate = curr_period.start;
                            this.dateRange.endDate = moment(this.invoice.period_start).isAfter(curr_period.end) ? moment(this.invoice.period_end).format('YYYY-MM-DD') : curr_period.end;
                        }
                    });
                }
                this.isLoadingFlag = true
                if (this.invoice && this.invoice.InvoiceLines) {
                    const retainedRevenueLines = this.invoice.InvoiceLines.filter((item) => item.Product.slug === "retained_revenue");
                    this.refund.amount = retainedRevenueLines.length ? retainedRevenueLines[0]?.cost : 0;
                }
            });
            console.log("sefguysegfyu", i)
        },
        isAuctionInvoiceFound() {
            let is_auction_invoice = false;
            if (this.invoice && this.invoice.InvoiceLines.length) {
                this.invoice.InvoiceLines.forEach(line => {
                    if (line.Product && line.Product.default_type === 'auction') {
                        is_auction_invoice = true;
                        this.showYieldButton = line.Product.slug === 'retained_revenue';
                    }
                });
                if (this.showYieldButton) {
                    let rr_adjustment_index = this.invoice.Payments && this.invoice.Payments.findIndex(appliedPayment =>
                        appliedPayment.Payment && appliedPayment.Payment.sub_method === 'retained_revenue'
                    );
                    if (rr_adjustment_index > -1) this.showYieldButton = false;
                }
            }
            return is_auction_invoice;
        },
        isInterPropertyFound() {
            let is_inter_property_invoice = false;
            if (this.invoice && this.invoice.InvoiceLines.length) {
                this.invoice.InvoiceLines.forEach(line => {
                    if (line.Product && line.Product.default_type === 'inter_property_adjustment') {
                        is_inter_property_invoice = true;
                    }
                });
                if (this.showYieldButton) {
                    let rr_adjustment_index = this.invoice.Payments && this.invoice.Payments.findIndex(appliedPayment =>
                        appliedPayment.Payment && appliedPayment.Payment.sub_method === 'retained_revenue'
                    );
                    if (rr_adjustment_index > -1) this.showYieldButton = false;
                }
            }
            return is_inter_property_invoice;
        },

        showWebView() {
            let routeData = this.$router.resolve("/reports.html?type=invoice&request_id=" + this.invoice.id);
            window.open(routeData.href, '_blank')
        },
        async download(invoice_id) {
            console.log("start");
            var data = {
                type: 'invoice',
                format: 'pdf',
                request_id: invoice_id,
            };

            let response = await api.post(this, api.REPORTS + 'download-pdf', data);
            console.log(response);
            const arr = new Uint8Array(response.data);
            var blob = new Blob([arr], { type: 'application/pdf' });
            console.log(blob);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            window.open(link.href, '_blank');

            // link.download = "Invoice_" + this.invoice.number;
            // link.click();

            //this.$store.commit('notificationsStore/startDocumentDownload', response);
        },
        async saveAdjustedInvoice(dryrun = true) {

            let paymentDetl = [];
            if (!dryrun) {
                const status = await this.$validator.validateAll("adjust");
                if (!status) return

                if (this.adjustPaymentOptions && this.adjustPaymentOptions.length) {
                    for (var i = 0; i < this.adjustPaymentOptions.length; i++) {
                        let p = this.adjustPaymentOptions[i].selectedOption;
                        paymentDetl.push({
                            id: p.Payment.id,
                            type: p.type,
                            amount: p.credit,
                        })
                    }
                }
            }

            let invoice = {
                dryrun: dryrun,
                ...this.$refs.viewInvoice.invoice,
                contact_id: this.contact_id,
                PaymentDetails: paymentDetl,
            };

            if (!dryrun && !(invoice.InvoiceLines.length && invoice.InvoiceLines.reduce((inital, line) => inital + parseInt(line.cost), 0) > 0)) {
                this.showEmptyInvoiceLineWarning = true;
                return;
            }
            api.post(this, api.INVOICES + 'adjust/' + this.invoice_id, invoice).then(r => {
                if (r.invoice && r.invoice.id) {
                    this.successSet(this.$options.name, `Invoice ${r.invoice.prior_invoice_number} has been adjusted.`);
                    this.$emit('refreshInvoice', r.invoice.id)
                    this.closeEdit()
                    this.showUnAppliedPaymentsUtilizationOptions = false
                    this.adjustmentConfirmationIfNoPayment = false;
                    EventBus.$emit('invoice_saved')
                } else {
                    this.paymentDetails = r.paymentDetails;
                    this.$refs.viewInvoice.invoice.total_tax = r.new_invoice.total_tax;
                    this.$refs.viewInvoice.invoice.discounts = r.new_invoice.discounts;
                    this.getAdjustPaymentOptions();
                }
            }).catch(err => {

                this.closeEdit();
                this.showUnAppliedPaymentsUtilizationOptions = false
                this.adjustmentConfirmationIfNoPayment = false;

                this.showMessageNotification({ description: err });
                this.$emit('close');
                this.$emit('refreshInvoice');
            });
        },
        async processReissueInvoice() {
            const status = await this.$validator.validateAll("reissue");
            if (!status) return

            let r = await api.post(this, api.INVOICES + 'reissue/' + this.invoice_id, { due: this.dueDate })
            if (r && r.invoice_id) {
                this.$emit('refreshInvoice', r.invoice_id);
                this.closeEdit();
                this.showReissueInvoice = false;
                EventBus.$emit('invoice_saved');
                this.showMessageNotification({ type: 'success', description: 'Reissued invoice created' });
            }
        },
        closeDialogue() {
            if (!this.adjustmentMode) {
                this.$emit('close')
            } else {
                this.invoice = JSON.parse(JSON.stringify(this.invoiceBackup));
            }

            this.closeEdit()
        },
        showRetainedRevenueModal() {
            this.showRefundModal = true
        },
        async processRetainedRevenue() {
            this.disableYieldButton = true;
            const status = await this.$validator.validateAll('refund_retained_revenue');
            if (!status) return;
            const invoiceLines = this.invoice && this.invoice.Payments.map((item) => {
                return {
                    invoices_payment_id: item.id,
                    amount: item.amount
                }
            })

            const data = {
                amount: this.refund.amount,
                is_prepay: false,
                reason: `Retained Revenue Overage Return to ${this.refund.paymentTo}.`,
                reversal_type: "overage_return",
                invoices: invoiceLines,
                is_auction_retained_revenue_refund: true,
                refund_to: this.refund.paymentTo,
                refund_contact_id: this.refund.paymentTo === 'tenant' ? this.invoice.contact_id : null
            }

            if (this.invoice && this.invoice.Payments) {
                await api.post(this, `${api.PAYMENTS}${this.invoice.Payments[0].payment_id}/refund`, data).then(async (r) => {
                    await this.fetchData();
                    this.$emit('refreshInvoice');
                    this.showRefundModal = false;
                    this.disableYieldButton = false;
                    this.showMessageNotification({ id: this.$options.name, type: "success", description: 'Retained Revenue Yield successfully' });
                }).catch((err) => {
                    this.disableYieldButton = false;
                    this.showMessageNotification({ id: this.$options.name, description: err });
                });
            }
        }
    },

    props: ['invoice_id', 'account_id', 'contact_id', 'type', 'fromVDialog', 'value', 'modal', 'fromMasterVueFile'],
    watch: {
        invoice_id: function () {
            this.state = 'view';
            this.fetchData();
            //    this.fetchPayments();
        },
        errorList() {
            if (this.errorHas(this.$options.name)) {
                this.showVoidInvoice = false
                this.showReissueInvoice = false
            }
        }
    }
}


</script>


<style scoped>
.slide-out-header-nav {
    border-bottom: 1px solid #dce8ef;
}

.slide-out-header-position-normal {
    position: static;
}

.key-label {
    background: #f4f6f8;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101318;
}

.display-invoice-header-title-wrapper {
    background: #E0F5F5;
    height: 52px;
}
</style>